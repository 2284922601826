<div class="col-12">
  <app-availability-day-time-picker></app-availability-day-time-picker>
  <form [formGroup]="availabilityForm">
    <!-- <app-student-availability-checkboxes [times]="times" [compact]="false" [(selectedFlags)]="selectedFlags"
        (selectedFlagsChange)="onSelectedFlagsChange($event)">
    </app-student-availability-checkboxes> -->

    <!-- <h3 class="align-items-center line-height-1 text-sm font-semibold mb-1 mt-3 primary-purple-color flex">
        Specific Availability
    </h3>
    <textarea class="w-full more-textarea h-7rem" rows="7" cols="35" [autoResize]="true" pInputTextarea
        formControlName="availabilitySupportedText"
        placeholder="Please specify any specific dates/times you would be available."></textarea> -->

    <h3 class="align-items-center line-height-1 text-sm font-semibold mb-1 mt-3 primary-purple-color flex">
      More Details
    </h3>
    <textarea [autoResize]="true" class="w-full more-textarea h-7rem mb-3" cols="35" formControlName="moreDetails" pInputTextarea
              placeholder="Tell us more about the students, their current level, schedule and goals they want to achieve by taking this course."
              rows="7"></textarea>

    <h3 class="align-items-center line-height-1 text-sm font-semibold mb-1 mt-3 primary-purple-color flex">
      Students Level <span class="text-red-600">*</span>
    </h3>
    <p-dropdown [filter]="true"
                [options]="levels"
                [virtualScrollItemSize]="28" [virtualScroll]="true" appendTo="body" dataKey="value" formControlName="studentLevel"
                optionValue="value" placeholder="Level" styleClass="w-full simple-form-input rounded purple-border primary-purple-color shadow-none dropdown-ct full-width mb-3">
    </p-dropdown>
  </form>
</div>
