import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  Inject,
  inject,
  Injector,
  type OnInit,
  signal
} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import {ImageBoxGroupSelectComponent} from '../../image-box-group-select/image-box-group-select.component';
import {CustomDialogPopupComponent} from '../../custom-dialog-popup/custom-dialog-popup.component';
import {
  FormFieldValidationMessageComponent
} from '../../prime/form-field-validation-message/form-field-validation-message.component';
import {ToastService} from 'src/app/core/services/toast.service';
import {ToastMessages} from 'src/app/shared/models/toast-messages';
import {EmitEvent, EventBusService, Events} from 'src/app/core/services/event-bus.service';
import {Observable, Subscription} from 'rxjs';
import {DataApiStateService, State} from 'src/app/core/services/data-api-state.service';
import {GeneralService} from 'src/app/core/services/general.service';
import {UserService} from 'src/app/core/services/user.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {untilDestroyed} from 'src/app/core/helpers/until-destroyed';
import {ApiService} from 'src/app/core/services/api.service';
import {StoudentGroupRuleMessage, StudentGroupService} from 'src/app/core/services/student-group.service';
import {Severity} from 'src/app/core/models/severity';
import {AccordionModule} from 'primeng/accordion';
import {
  ImageBoxGroupItemComponent
} from '../../image-box-group-select/image-box-group-item/image-box-group-item.component';
import {CarouselModule} from 'primeng/carousel';
import {CarouselService} from 'src/app/core/services/carousel.service';
import {
  StudentGroupSelectionSuggestionTextStepComponent
} from './student-group-selection-suggestion-text-step/student-group-selection-suggestion-text-step.component';
import {
  StudentAvailabilityCheckboxesComponent
} from '../../student-availability-checkboxes/student-availability-checkboxes.component';
import {
  StudentGroupSelectionMembersStepComponent
} from './student-group-selection-members-step/student-group-selection-members-step.component';
import {
  ICreateStudentGroupRequest,
  IGetStudentGroupResponse,
  IStudentBasicInfoDto,
  IStudentGroupDto,
  ITeachingLanguageDto,
  StudentGroupRoutes
} from '@GeneratedTsFiles/index';
import {toObservable} from '@angular/core/rxjs-interop';
import {
  StudentGroupSelectionAvailabilityStepComponent
} from './student-group-selection-availability-step/student-group-selection-availability-step.component';
import {
  StudentGroupSelectionSuccessActionStepComponent
} from './student-group-selection-success-action-step/student-group-selection-success-action-step.component';


export enum GroupDialogState {
  None = -1,
  CreateGroupSuggestionStep = 0,
  CreateGroup = 1,
  CreateGroupAvailability = 2,
  DeleteGroup = 3,
  EditAvailability = 4,
  EditMembers = 5,
  AfterCreateSuccess = 6,
  AfterEditSuccess = 7,
}

@Component({
  selector: 'app-student-group-selection-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    ButtonModule,
    AccordionModule,
    CarouselModule,
    ImageBoxGroupSelectComponent,
    CustomDialogPopupComponent,
    FormFieldValidationMessageComponent,
    ImageBoxGroupItemComponent,
    StudentGroupSelectionSuggestionTextStepComponent,
    StudentAvailabilityCheckboxesComponent,
    StudentGroupSelectionAvailabilityStepComponent,
    StudentGroupSelectionMembersStepComponent,
    StudentGroupSelectionSuccessActionStepComponent,
  ],
  templateUrl: './student-group-selection-dialog.component.html',
  styleUrl: './student-group-selection-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CarouselService,
    StudentGroupService,
  ]
})
export class StudentGroupSelectionDialogComponent implements OnInit {
  // Define signals
  readonly EditGroupState = GroupDialogState;
  dialogVisible = signal(true);
  dialogStyleClass = signal('p-dialog p-dialog-md purple-dialog-header');
  dialogStyle = signal({width: '50vw'});
  dialogHeader = signal('Create a Student Group');
  editMode = signal(false);
  step = signal(1);
  studentGroupItem = signal({} as IStudentGroupDto);
  editMembersMode = signal(false);
  editGroupState = signal(this.EditGroupState.CreateGroupSuggestionStep);
  canEditGroup = signal(false);
  deleteGroupContent = signal(false);
  newGroupMemberContent = signal(false);

  // Injected services
  generalService = inject(GeneralService);
  authService = inject(AuthService);
  userService = inject(UserService);
  apiService = inject(ApiService);
  studentGroupService = inject(StudentGroupService);
  dataStateService = inject(DataApiStateService);
  toastService = inject(ToastService);
  eventBusService = inject(EventBusService);
  carouselService = inject(CarouselService);

  // Computed signals
  dialogStyle$ = computed(() => this.dialogStyle());
  user = computed(() => {
    console.log(this.#userToSignal());
    return this.#userToSignal();
  });
  dialogHeader$ = computed(() => {

    if (this.editMode()) {
      return 'Edit Student Group';
    } else {
      switch (this.editGroupState()) {
        case this.EditGroupState.None:
          return 'Create Your Group Class in 3 Easy Steps!';
        case this.EditGroupState.CreateGroup:
          return 'Select your Students';
        case this.EditGroupState.DeleteGroup:
          return 'Delete Group';
        case this.EditGroupState.EditAvailability:
          return 'Edit Group Availability';
        case this.EditGroupState.EditMembers:
          return 'Edit Group Members';
        case this.EditGroupState.AfterCreateSuccess:
          return 'Group successfully created';
        case this.EditGroupState.AfterEditSuccess:
          return 'Edit Student Group';
      }
    }
    return 'Create a Student Group';
  });

  // Other properties and methods
  canGoBack = signal(false);
  Severity = Severity;
  canGoBack$ = computed(() =>
    this.newGroupMemberContent()
    || this.editMembersMode()
    || this.editMode()
    || this.editGroupState() === this.EditGroupState.CreateGroupAvailability
    && this.editGroupState() !== this.EditGroupState.None
    && this.editGroupState() !== this.EditGroupState.AfterEditSuccess
  );
  #userToSignal = this.authService.userDecodedJWTData$;
  selectedStudents = signal([] as any[]);
  selectedTeachingLanguage = {} as ITeachingLanguageDto;
  selectedTeachingLanguage$ = signal<ITeachingLanguageDto | null>(null);
  selectedLanguageLevel$ = signal<any | null>(null);
  resetSelectionSignal = signal(false);
  ruleMessageDisplay = signal([] as StoudentGroupRuleMessage[]);
  destroy: DestroyRef = inject(DestroyRef);
  subscriptions: Subscription[] = [];
  parameters: any;
  times: { time: string, flag: any, checked: boolean, day: string }[] = [];
  selectedFlags: number | undefined | any = undefined;
  preselectedStudents = [] as IStudentBasicInfoDto[];
  studentGroups = [] as IStudentGroupDto[];

  studentGroups$ = computed(() => this.dataStateService.parentStudentsGroups.state() as State<any>);
  createStudentGroup$ = computed(() => this.dataStateService.createStudentGroup.state() || [] as State<any>[]);
  untilDestroyed = untilDestroyed();
  injector = inject(Injector);

  constructor(@Inject('dialogParameters') parameters: any) {
    this.parameters = (parameters);
  }

  ngOnInit(): void {
    this.initializeComponent();
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.generalService.setErrorDataSignal('');
  }

  membersActionCompleted(event: any): void {
    this.editGroupState.set(this.EditGroupState.None);
  }

  private initializeComponent(): void {
    this.editMode.set(this.parameters.editMode);
    this.studentGroupItem.set(this.parameters.studentGroupItem);
    this.dialogStyleClass.set('p-dialog p-dialog-md purple-dialog-header');
    this.dialogStyle.set({width: '650px'});

    if (this.editMode()) {
      this.initializeEditMode();
      this.dialogHeader.set('Edit Student Group');
    }

    if (this.studentGroupItem()) {
      this.preselectedStudents = this.studentGroupItem().studentsBasicInfo;
    }

    this.initEvents();
  }

  private setupSubscriptions(): void {
    this.subscribeToStudentGroups();
  }


  /**
   * Initializes the component in edit mode. Sets the step to 2 (Student Selection),
   * pre-selects the teaching language, level and students from the given StudentGroupItem,
   * and sets the initial filtered students list.
   */
  private initializeEditMode(): void {
    // this.step.set(2);
    this.editGroupState.set(this.EditGroupState.None);
  }

  private initEvents(): void {
    this.eventBusService.emit(new EmitEvent(Events.StateLoadTeachingLanguages, undefined));
    this.subscribeToEvents();
  }

  onGroupStateChanged(state: GroupDialogState): void {
    this.editGroupState.set(state);
    if (state === GroupDialogState.CreateGroup) {
      this.editMode.set(false);
    } else if (state === GroupDialogState.EditMembers) {
    }
  }

  onGroupItemChanged(item: IStudentGroupDto): void {
    console.log(item);
    this.studentGroupItem.set(item);
  }

  onSuggestionTextButtonSelected($event: any): void {
    this.step.set(2);
    this.editGroupState.set(this.EditGroupState.CreateGroup);
  }

  deleteGroupStepSelected() {
    this.deleteGroupContent.set(true);
    this.editGroupState.set(this.EditGroupState.DeleteGroup);
  }

  deleteGroupStepActionSelected(action: string): void {
    if (action === 'yes') {
      this.deleteGroupContent.set(true);
      this.deleteStudentGroup();
    } else if (action === 'no') {
      this.deleteGroupContent.set(false);
    }
  }

  private deleteStudentGroup(): void {
    this.apiService.getApiData<IGetStudentGroupResponse>(
      {url: StudentGroupRoutes.deleteStudentGroup, method: 'DELETE'},
      {
        parentId: this.user()?.id,
        groupId: this.studentGroupItem().id
      }).pipe(
      this.untilDestroyed()
    ).subscribe({
      next: (response: IGetStudentGroupResponse) => {
        console.log(response);
        this.eventBusService.emit(new EmitEvent(Events.StateLoadParentStudentsGroups, undefined));
        this.toastService.show(ToastMessages.StudentsGroupDelete.success);
        this.dialogVisible.set(false);
      },
      error: this.handleApiError
    });
  }

  private handleApiError = (error: any): void => {
    console.error('API Error:', error);
    // Handle error (e.g., show error message)
  }

  backToViewGroup() {
    this.editGroupState.set(this.EditGroupState.CreateGroup);
    this.editMembersMode.set(false);
    this.deleteGroupContent.set(false);
    this.newGroupMemberContent.set(false);
  }

  private subscribeToEvents(): void {
    const subscriptions = [
      this.eventBusService.on(Events.StudentGroupAdded, this.handleStudentGroupAdded),
      this.eventBusService.on(Events.StudentGroupRemoved, this.handleStudentGroupRemoved),
      this.eventBusService.on(Events.StudentGroupNewMemberAdded, this.handleStudentGroupNewMemberAdded)
    ];
    this.subscriptions.push(...subscriptions);
  }

  private handleStudentGroupAdded = (payload: any): void => {
    console.log(`Customer Selected: ${payload.name}`);
  }

  private handleStudentGroupRemoved = (payload: any): void => {
    console.log(Events.StudentGroupRemoved, `: ${payload}`);
  }

  private handleStudentGroupNewMemberAdded = (payload: any): void => {
    console.log(Events.StudentGroupNewMemberAdded, `: ${payload}`);
  }

  private subscribeToStudentGroups(): void {
    toObservable(this.studentGroups$, {injector: this.injector})
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (stateData: any) => {
          console.log(stateData);
          if (stateData.data?.studentGroups) {
            if (this.editMode()) {
              this.studentGroupItem.set(this.studentGroupService.findGroupById(stateData.data.studentGroups, this.studentGroupItem().id) as IStudentGroupDto);

            }
          }
        }
      });
  }

  onMembersStepSubmitted(event: Partial<ICreateStudentGroupRequest>): void {
    this.editGroupState.set(this.EditGroupState.CreateGroupAvailability);
  }

  onAvailabilityStepSubmitted(): void {
    this.createNewGroup();
  }

  onAvailabilityStepValuesChanged(event: any): void {
    console.log(this.studentGroupService.getStudentGroupRequest());
  }

  /**
   * Calls the API to create a student group.
   *
   * Emits: this.handleCreateGroupSuccess, this.handleCreateStudentGroupError
   */
  createNewGroup(): void {

    const apiCall: Observable<any> = this.userService.createStudentGroup(this.studentGroupService.getStudentGroupRequest());

    this.dataStateService.handleApiCall(apiCall, this.dataStateService.createStudentGroup.setState)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: this.handleCreateGroupSuccess,
      });
  }


  private handleCreateGroupSuccess = (): void => {
    this.editGroupState.set(this.EditGroupState.AfterCreateSuccess);
    this.toastService.show(ToastMessages.StudentsGroupAdd.success);
    this.eventBusService.emit(new EmitEvent(Events.StudentGroupAdded, {name: 'Group 1'}));

  }

  private handleEditGroupSuccess = (): void => {
    this.eventBusService.emit(new EmitEvent(Events.StudentGroupEdited, undefined));
    this.toastService.show(ToastMessages.StudensGroupEdit.success);
    this.editGroupState.set(this.EditGroupState.AfterEditSuccess);
  }


  private handleCreateStudentGroupError = (error: any): void => {
    console.error(error);
    if (error.statusCode === 400) {
      // Handle 400 error
    }
    this.generalService.handleRouteError(error, StudentGroupRoutes.postCreateStudentGroup);
  }

}
