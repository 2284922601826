import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, inject, input, Output, type OnInit} from '@angular/core';
import {
  StudentAvailabilityCheckboxesComponent
} from '../../../student-availability-checkboxes/student-availability-checkboxes.component';
import {
  ICreateStudentGroupRequest,
  IStudentGroupDto,
  IStudentLevelEnum
} from '@GeneratedTsFiles/index';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DropdownModule} from 'primeng/dropdown';
import {untilDestroyed} from 'src/app/core/helpers/until-destroyed';
import {StudentGroupService} from 'src/app/core/services/student-group.service';
import {
  AvailabilityDayTimePickerComponent
} from '../../../availability-day-time-picker/availability-day-time-picker.component';

@Component({
  selector: 'app-student-group-selection-availability-step',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule,
    DropdownModule,
    StudentAvailabilityCheckboxesComponent,
    AvailabilityDayTimePickerComponent,
  ],
  templateUrl: './student-group-selection-availability-step.component.html',
  styleUrl: './student-group-selection-availability-step.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentGroupSelectionAvailabilityStepComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  studentGroupService = inject(StudentGroupService);
  private untilDestroyed = untilDestroyed();

  times: { time: string, flag: any, checked: boolean, day: string }[] = [];
  selectedFlags: number | undefined | any = undefined;
  availabilityForm: FormGroup = new FormGroup({});
  IStudentLevelEnum = IStudentLevelEnum;
  levels = [
    {label: 'Select a Level *', value: null},
    {label: 'No Experience', value: IStudentLevelEnum.NoExperience},
    {label: 'Beginner', value: IStudentLevelEnum.Beginner},
    {label: 'Intermediate', value: IStudentLevelEnum.Intermediate},
    {label: 'Advanced', value: IStudentLevelEnum.Advanced}
  ];
  studentGroupItem = input.required<IStudentGroupDto>({});
  IStudentAvailabilityEnum = [];
  @Output() groupStateChanged: EventEmitter<any> = new EventEmitter();
  @Output() onAvailabilityStepValuesChanged: EventEmitter<Partial<ICreateStudentGroupRequest>> = new EventEmitter();

  ngOnInit(): void {
    this.initializeForm();
    this.subscribeToFormChanges()
  }

  private initializeForm(): void {

    this.availabilityForm = this.formBuilder.group<Partial<any>>({
      availability: undefined,
      availabilitySupportedText: undefined,
      moreDetails: undefined,
      studentLevel: undefined,
    });
  }

  private subscribeToFormChanges(): void {
    this.availabilityForm.valueChanges
      .pipe(this.untilDestroyed())
      .subscribe((values) => {
        console.log('Form Values Changed:', values);
      });

    this.availabilityForm.statusChanges
      .pipe(this.untilDestroyed())
      .subscribe((status) => {
        console.log('Form Status Changed:', status);
        if (status === 'INVALID') {
          // Handle invalid form status
          console.error('Form is invalid');
        } else {
          // Handle valid form status
          console.log('Form is valid');
          this.handleValidForm();
        }
      });
  }

  // Method to handle actions when the form is valid
  private handleValidForm(): void {
    // Implement your logic here
    this.studentGroupService.updateStudentGroupRequest({
      ...this.studentGroupService.getStudentGroupRequest(),
      registerStudentToTeachingLanguageDto: this.studentGroupService.getStudentGroupRequest().registerStudentToTeachingLanguageDto,
    });
    this.onAvailabilityStepValuesChanged.emit(this.availabilityForm.value);
  }

  onSelectedFlagsChange(selectedFlags: number | undefined | any): void {
    console.log(selectedFlags);
    this.selectedFlags = selectedFlags;
    // Update the 'availability' field in the form based on selectedFlags
    this.availabilityForm.patchValue({
      availability: selectedFlags, // Update availability to the new selected flags
    });
  }
}
